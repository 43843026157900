.skin-black .main-header .navbar .sidebar-toggle {
    position: absolute;
    top: 0;
    left: -230px;
    height: 50px;
    border: none;
}
.skin-black .main-header .logo {
    padding: 0 0 0 40px;
    border: none !important;
}
.skin-black .sidebar-menu>li>a {
    border-left: none;
}
.skin-black .sidebar a {
    padding: 18px 0 18px 24px;
    border-top: solid 1px #3E484D;
}
.skin-black .sidebar a:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translate(0, -50%);
    background: url('../img/icon-chevron-right.svg') no-repeat center;
    background-size: contain;
    width: 12px;
    height: 12px;
    opacity: 0;
    transition: all 300ms ease;
}
.skin-black .sidebar a span {
    display: block;
    transition: all 300ms ease;
}
.skin-black .sidebar a:hover > span {
    transform: translate(16px, 0);
}
.skin-black .sidebar a:hover:before {
    transform: translate(12px, -50%);
    opacity: 1;
}

@media (max-width: 767px) {
    .skin-black .main-header .navbar .sidebar-toggle {
        top: -50px;
        left: 0;
    }
    .main-header .navbar {
        min-height: 0;
    }
}