.login {
    &-page {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        background-color: #03265B;
        margin: 0;
        padding: 0;
    }

    &-box {
        margin: 0 auto;
        text-align: center;
        width: 300px;

        input {
            width: 280px;
            padding: 10px;
            border-radius: 5px;
            margin: 5px 0;
            border: none;
        }

        .submit-area {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 5px;
        }

        #remember_me {
            width: 20px;
        }

        .remember_me {
            color: #fff;
        }

        .btn-primary {
            background-color: #F9D74A;
            border-color: #F9D74A;
            padding: 10px 30px;
            border-radius: 5px;

            &:hover {
                background-color: #03265B;
                color: #F9D74A;
            }
        }

        .copyright {
            color: #fff;
            padding-top: 20px;
        }

        .logo {
            width: 150px;
        }

        .alert {
            color: #fd0202;
        }
    }
}

.squaredThree {
    width: 20px;
    position: relative;

    label {
        width: 20px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(top, #222 0%, #45484d 100%);
        border-radius: 4px;
        border: 1px solid #fff;

        &:after {
            content: '';
            width: 9px;
            height: 5px;
            position: absolute;
            top: 4px;
            left: 4px;
            border: 3px solid #F9D74A;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
        }

        &:hover::after {
            opacity: 0.3;
        }

        span {
            position: absolute;
            width: 165px;
        }
    }

    input[type=checkbox] {
        visibility: hidden;

        &:checked + label:after {
            opacity: 1;
        }
    }
}
.skin-black .main-header .logo {
    padding: 0;

    img {
        width: 140px;
        height: auto;
        margin-top: 4px;
        margin-left: 90px;
    }
}

.sidebar:after {
    content: " - Radost z plavání";
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: white;
    text-align: right;
}

.sidebar:before {
    content: " ";
    width: 75px;
    height: 40px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-image: url('../img/happy-white.png');
    background-repeat: no-repeat;
    background-size: contain;
}
