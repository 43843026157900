.show-example-lesson{
    table{
        /*table-layout: fixed;*/
        width: 100%;
        font-size: 15px;
    }
    table,tbody, th, td {
        border: 1px solid gray !important;
        border-collapse: collapse;
        text-align: left;
    }
    .gray-bg{
        background-color: rgb(218, 222, 229);
    }
}