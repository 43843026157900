/* #FFD600 */

@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html,
body,
html * :not(i) {
    font-family: "Roboto", sans-serif !important;
}

.main-header .logo {
    background-color: #ffd600 !important;
    border-right: 1px solid #000 !important;
}

.main-header .navbar {
    background-color: #ffd600 !important;
}

.skin-black .main-header .navbar > .sidebar-toggle {
    font-family: "Font Awesome 5 Free" !important;
    border-right: 1px solid #000;
}

.skin-black .main-header .navbar .sidebar-toggle:hover {
    background: #000;
    color: #ffd600;
}

.main-header .sidebar-toggle:before {
    content: "\F0C9";
}

.btn-primary {
    background-color: #0062cc;
    border-color: #007bff;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-success {
    background-color: #0f9d58;
}
.btn-success:hover {
    border-color: #0f9d58;
}

.btn-danger {
    background-color: #db4437;
}
.btn-danger:hover {
    border-color: #db4437;
}
.group-note {
    margin: 20px 0 40px;
}

.content-wrapper .content img {
    max-width: 100%;
}