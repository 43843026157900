#lesson-example-builder input, #lesson-example-builder textarea{
    max-width: 100%;
    width: 100%;
    resize: vertical;
}
#lesson-example-builder th{
    padding: 0;
}
#lesson-example-builder .number{
    text-align: center;
    line-height: 25px;
}
#lesson-example-builder table th{
    /*border-color: black;*/
}
.lessonHead #lesson_date_time{
    display: inline;
    line-height: 30px;
}
.lessonHead th{
    padding: 2px !important;
}
.lessonHead input{
    width: 100%;
}
.lessonHead #lesson_date_date{
    width: auto !important;
}
.lessonHead label{
    line-height: 25px !important;
}
#removeButton{
    width: 150px;
    position: fixed;
    bottom: 0;
}
#buttonsScroll{
    overflow-y: scroll;
    overflow-x: hidden;
    position: fixed;
}
#buttonsScroll.box{
    width: 100%;
}
#buttonsGroups .box-title{
    line-height: 30px;
}
#buttonsScroll.fixed{
    top: 0;
    height: 100% !important;
    background-color: #ecf0f5;
}